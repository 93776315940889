<template>
	<div>
		<Footer />
		<div class="content" v-if="showif">

			<p class="contenTitle">账号注册</p>
			<p style="text-align: center;">
				<img class="cross" src="./register/cross.png" alt="">
			</p>
			<p style="text-align: center;">
				<span class="contentSpan">您的手机号：</span>
				<input type="text" class="contentInput"  v-model="telephone" @blur.native.capture="sendcode()">
				&emsp;&emsp;
				<span class="contentSpan">您的姓名：</span><input type="text" class="contentInput" v-model="real_name">
			</p>

			<p style="text-align: center;">
				<span class="contentSpan">您的邮箱：</span><input type="text" class="contentInput" @blur.native.capture="isEmail()"  v-model="email">
				&emsp;&emsp;
				<span class="contentSpan">您的身份证号：</span><input type="text" class="contentInput"  @blur.native.capture="isId_num()" v-model="id_num">
			</p>

			<p style="text-align: center;">
				<span class="contentSpan">密码：</span>
				<input type="password" class="contentInput" v-model="password" @blur.native.capture="isPassword()"  >
				<!-- <el-input class="contentInput" v-model="password" show-password :blur="isPassword()"></el-input> -->
				&emsp;&emsp;
				<span class="contentSpan">您所在的学校：</span><input type="text" class="contentInput" v-model="school">
			</p>
			<p style="text-align: center;">
				<span class="contentSpan">确认密码：</span>
				<el-input class="contentInput" v-model="Qpassword" show-password></el-input>
				&emsp;&emsp;
				<span class="contentSpan">您所在的省份：</span><input type="text" class="contentInput" v-model="provinces">
			</p>

			<div class="contentBox">
				<p><span class="contentSpan">您所在的学院：</span><input type="text" class="contentInput" v-model="college">
				</p>
				<p><span class="contentSpan">您的职务：</span><input type="text" class="contentInput" v-model="post"></p>
			</div>

            <div class="contentDiv">
                <div><img src="./register/show.jpg" alt=""></div>
                <p class="uploadSpan">注：大唐杯学生赛事采用学校教师代表集中报名的方式进行报名，每所学校设置三名教师代表。上传教师资格证姓名页照片审核通过后即可报名。</p>
            </div>
            <div class="footer">
				<el-upload ref="upload" action="" :http-request="httpRequest"
                    :limit="1"
					:on-remove="handleRemove" :on-change="onChange" :before-upload="beforeAvatarUpload"
					:on-success="handleUploadSuccess" :file-list="fileList">
					<el-button type="warning" size="mini">上传教师资格证</el-button>
					<div class="el-upload__tip">注：只能上传jpg/png文件</div>
				</el-upload>
				<!-- 	<img :src=url alt=""> -->
			</div>

			<p style="text-align: center;">
				<el-button type="info" id="contentBut" @click="step">下一步</el-button>
			</p>
			<div class="contentText">
				<span>大赛注册及报名信息收集仅用于大赛组委会审核与竞赛，我们将高度重视您的个人信息安全，并采取符合法律法规的安全措施，保护您的个人信息，防止发生个人信息的泄露、丢失、等安全风险。</span>
			</div>
		</div>


		<div class="content" v-if="iFshow">
			<p class="contenTitle">确认您的信息</p>
			<p style="text-align: center;">
				<img class="cross" src="./register/crossR.png" alt="">
			</p>
			<p style="text-align: center;">
				<span class="contentSpan">您的手机号：</span><input type="text" class="contentInput" v-model="telephone"
					readonly>
				&emsp;&emsp;
				<span class="contentSpan">您的姓名：</span><input type="text" class="contentInput" v-model="real_name"
					readonly>
			</p>
			<p style="text-align: center;">
				<span class="contentSpan">您的邮箱：</span><input type="text" class="contentInput" v-model="email" readonly>
				&emsp;&emsp;
				<span class="contentSpan">您的身份证号：</span><input type="text" class="contentInput" v-model="id_num"
					readonly>
			</p>

			<div class="contBox">
				<p><span class="contentSpan">您所在的学校：</span><input type="text" class="contentInput" v-model="school"
						readonly></p>
				<p><span class="contentSpan">您所在的省份：</span><input type="text" class="contentInput" v-model="provinces"
						readonly></p>
			</div>

			<div class="contBoxT">
				<p><span class="contentSpan">您所在的学院：</span><input type="text" class="contentInput" v-model="college"
						readonly></p>
				<p><span class="contentSpan">您的职务：</span><input type="text" class="contentInput" v-model="post"
						readonly></p>
			</div>

			<div class="Boximg">
				<div><img style="width:4rem; height: auto;" :src=url alt=""></div>
			</div>

			<p class="Title">比赛报名期间，我们将于1-2个工作日内提交组委会进并完成信息审核，审核通过后会给您发送邮件通知，届时使用手机号登录后即可进行报名。</p>


			<p style="text-align: center;">
				<el-button type="info" id="contentBut" :loading="dis" @click="submit">提交注册信息</el-button>
			</p>
			<div class="contentText">
				<span>大赛注册及报名信息收集仅用于大赛组委会审核与竞赛，我们将高度重视您的个人信息安全，并采取符合法律法规的安全措施，保护您的个人信息，防止发生个人信息的泄露、丢失、等安全风险。</span>
			</div>
		</div>
		<el-dialog title="提示" v-model="dialogVisible" width="20%" :before-close="handleClose">
			<div style="padding:.1rem">我们已收到您的注册信息，审核通过后即可登录！</div>
			<div style="margin-top:.2rem;text-align: right;">
				<el-button type="primary" @click="jump">确 定</el-button>
			</div>
		</el-dialog>
		<Bottom />
	</div>
</template>
<script>
	import Footer from '@/components/Footer/Footer.vue'
	import Bottom from '@/components/Bottom/Bottom.vue'
	import {method} from 'lodash';
	import {getLogin} from "api/apis.js"
	// import {client,headers} from '@/tools/oss.js'
	import {httpRequest} from '@/tools/common.js'
	import {Search} from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	export default {
		name: 'Register',
		components: {
			Footer,
			Bottom
		},
		data() {
			return {
				telephone: "", //手机号
				real_name: "", //真实姓名
				email: "", //邮箱
				id_num: "", //身份证号
				password: "", //密码
				Qpassword: "", //确认密码
				school: "", //学校
				college: "", //学院
				post: "", //职位
				provinces: "", //省份
				showif: true,
				iFshowPhone: false,
				iFshowEmail: false,
				iFshowPassword: false,
				isFormID: false,
				fileList: [],
				Search: Search,
				time: new Date(),
				fileList: [],
				url: '',
				dialogVisible: false,
				isForm:false,
				dis:false

			}
		},
		created() {},
		mounted() {},
		methods: {
			// 手机验证
			sendcode() {
				let reg = /^1[3-9]\d{9}$/;
				if (this.telephone == '') {
					this.$message.error("请输入手机号码");
				} else if (!reg.test(this.telephone)) {
					this.$message.error("手机格式不正确");
					this.iFshowPhone = false;
				}else{
					this.iFshowPhone = true;
				}
			},
			// 邮箱验证
			isEmail(){
				let myreg=/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
				if (this.email == '') {
					this.$message.error("请输入邮箱");
				} else if (!myreg.test(this.email)) {
					this.$message.error("邮箱格式不正确");
					this.iFshowEmail = false;
				}else{
					this.iFshowEmail = true;
				}
			},
			// 密码验证
			isPassword(){
				if (this.password.length < 6) {
					this.$message.error("密码不能少于6位数");
					this.iFshowPassword = false;
				}else{
					this.iFshowPassword = true;
				}
			},
			// 身份证验证
			isId_num(){
				let myreg=/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
				if (this.id_num == '') {
					this.$message.error("请输入身份证");
				} else if (!myreg.test(this.id_num)) {
					this.$message.error("身份证格式不正确");
					this.isFormID = false;
				}else{
					this.isFormID = true;
				}
			},
			// 上传的方法
			onChange(file, fileList) {
				console.log(file, 'file', fileList, 'Filest');
				this.fileList = fileList
			},
			handleUploadSuccess(response, file, fileList) {
				console.log(response, file, fileList, 'response, file, fileList')
			},
			handleRemove(e, fileList) {
				this.fileList = fileList;
			},
			beforeAvatarUpload(file) {
				// 文件大小校验
				const isLt2M = file.size / 1024 / 1024 < 10;
				if (!isLt2M) {
					this.$message.error('文件大小不能超过 10M !');
				}
				return isLt2M;
			},

			//回调接收上传成功的参数
			getHttpRelut(res) {
				//业务逻辑
				console.log(res);
				this.url = res.url
				console.log(this.url);

			},
			//上传时的oss 
			// user_register// 用户注册
			// sign_up_audit// 审核 
			httpRequest(file) {
				httpRequest(file, "user_register", this.getHttpRelut);
			},

			// 下一步
			step() {
				if (this.telephone === "" || this.real_name === "" || this.email === "" || this.id_num === "" || this
					.password === "" || this.Qpassword === "" || this.school === "" || this.college === "" || this.post ===
					"" || this.provinces === "" || this.url === "") {
					this.$message.error('请输入完整信息!');
					return false
				}
				if (this.password != this.Qpassword) {
					this.$message.error('两次密码输入不一致！');
					return false
				}
				if(this.iFshowPhone && this.iFshowEmail && this.iFshowPassword && this.isFormID){
					this.showif = false;
					this.iFshow = true;
				}else{
					this.$message.error("请检查信息格式是否有误");
				}
				
			},
			//点击提交时触发
			// telephone:"",//手机号
			// real_name:"",//真实姓名
			// email:"",//邮箱
			// id_num:"",//身份证号
			// password:"",//密码
			// school:"",//学校
			// college:"",//学院
			// post:"",//职位
			// provinces:"",//省份
			submit() {
				this.dis = true
				let dataobj = new FormData();
				dataobj.append('telephone', this.telephone); //手机号
				dataobj.append('real_name', this.real_name); //真实姓名
				dataobj.append('email', this.email); //邮箱
				dataobj.append('id_num', this.id_num); //身份证号
				dataobj.append('password', this.password); //密码
				dataobj.append('school', this.school); ////学校
				dataobj.append('college', this.college); ////学院
				dataobj.append('post', this.post); //职位
				dataobj.append('province', this.provinces); //省份
				dataobj.append('picture_path', this.url); //教师资格证

				getLogin.Registered(dataobj).then(res => {
					if (res.code == 200) {
						console.log(res)
						this.dialogVisible = true;
					}
					if(res.code == 0) {
						ElMessage({
									message: res.msg,
									type: "error",
								});
						this.dialogVisible = true;
					}
					if(res.code == 1) {
						ElMessage({
									message: res.msg,
									type: "error",
								});
					this.$router.push("/Register");
					this.dialogVisible = true;
					}
					if(res.code == 2) {
						ElMessage({
									message: res.msg,
									type: "error",
								});
						this.$router.push("/HomePage");
						this.dialogVisible = true;
					}

				setTimeout(() => {
					this.dis = false
				}, 3000);

				})
			},
			jump() {
				this.dialogVisible = false;
				setTimeout(() => {
					this.$router.push('/HomePage')
				}, 2000);
			}
		}
	}
</script>
<style lang="scss" scoped>
	:deep .el-upload__tip {
		font-size: .14rem;
		margin: auto .1rem;

	}

	:deep .el-upload {
		margin-left: 2.6rem;
	}

	:deep .el-upload>button {
		font-size: .14rem;
		padding: .12rem;
	}

	:deep .el-upload-list--picture .el-upload-list__item {
		width: 2rem;
		margin: auto
	}

	input[readonly] {
		color: #929292
	}

	:deep(.el-input__wrapper) {
		box-shadow: 0 0 0 0px var(--el-input-border-color, var(--el-border-color)) inset;
		cursor: default;

		.el-input__inner {
			cursor: default !important;
		}
	}

	:deep .el-upload-list__item-info {
		margin-left: 3.2rem;
		display: inline-block;
	}

	/deep/ .el-dialog__header {
		background: #d7edfb !important;
		margin-right: 0 !important;
	}

	@import './registered.less';
</style>
